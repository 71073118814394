import React from "react";
import "../css/global.css";
import { graphql, useStaticQuery } from "gatsby";

const Tags = ({ handleTagClick }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            tags
          }
        }
      }
    }
  `);
  const tagMap = {};
  const { nodes } = data.allMarkdownRemark;
  nodes.forEach(n => {
    n.frontmatter.tags.forEach(t => {
      if (tagMap[t]) tagMap[t]++;
      else tagMap[t] = 1;
    });
  });
  let tags = [{ tag: "ALL", n: nodes.length }];
  for (const key in tagMap) {
    if (tagMap.hasOwnProperty(key)) tags.push({ tag: key, n: tagMap[key] });
  }
  tags = tags.sort((a, b) => b.n - a.n);

  return (
    <div className="px-2 flex flex-wrap">
      {tags.map(t => (
        <div
          onClick={() => handleTagClick(t.tag)}
          key={t.tag}
          className="cursor-pointer p-1 hover:text-orange-400"
        >
          <span className="truncate bg-green-100 rounded-full p-1 text-sm">
            {`${t.tag} (${t.n})`}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Tags;
