import React from "react";
import { Link } from "gatsby";
import "../css/global.css";

const Posts = ({ posts }) => {
  return (
    <div className="flex-col">
      {posts.map(p => (
        <div key={p.id}>
          <Link className="hover:text-orange-400" to={p.frontmatter.path}>
            <img
              className="mb-1 hover:opacity-75"
              src={p.frontmatter.photo}
              alt="thumbnail"
            />
            <h2 className="text-sm mb-0">{p.frontmatter.title}</h2>
            <footer className="text-xs mb-4">{p.frontmatter.date}</footer>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Posts;
