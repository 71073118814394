import React from "react";
import "../css/global.css";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Tags from "./tags.jsx";

const Card = ({ handleTagClick }) => (
  <div>
    <div className="md:mr-4 mb-4 max-w-md shadow-lg overflow-hidden rounded">
      <div className="w-full bg-orange-200">
        <img
          src="https://res.cloudinary.com/sveto/image/upload/v1558725571/blog/SvetoPhoto-removebg_sr5m0g.png"
          alt="headshot"
        />
      </div>
      <div className="flex justify-center ">
        <a
          className="text-xl p-1 hover:text-orange-400"
          href="https://github.com/korytiak"
        >
          <FaGithub />
        </a>
        <a
          className="text-xl p-1 hover:text-orange-400"
          href="https://www.linkedin.com/in/svetozar-korytiak-551a8419a/"
        >
          <FaLinkedinIn />
        </a>
      </div>
      <div className="p-5">
        <h2 className="font-bold mb-2 text-xl">Hey friends!</h2>
        <p className="text-base">
          Welcome to my blog, where I share thoughts on number of topics:
        </p>
        <Tags handleTagClick={handleTagClick} />
        <div className="flex items-center border-t mt-2">
          <MdEmail /> <span className="pl-1">korytiak.s@gmail.com</span>
        </div>
      </div>
    </div>
  </div>
);

export default Card;
