import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/global.css";
import Card from "../components/card";
import Posts from "../components/posts";

const IndexPage = ({ data }) => {
  const allPosts = data.allMarkdownRemark.nodes;
  const [state, setState] = React.useState({
    filteredPosts: [],
    tag: "ALL"
  });
  const handleTagClick = tag => {
    if (tag === "ALL") {
      setState({ tag });
      return;
    }
    const filteredPosts = allPosts.filter(
      p => p.frontmatter.tags.indexOf(tag) > -1
    );
    setState({ tag, filteredPosts });
  };
  const { tag, filteredPosts } = state;
  const posts = tag !== "ALL" ? filteredPosts : allPosts;
  return (
    <Layout>
      <SEO title="Home" />
      <div className="flex flex-wrap md:flex-no-wrap">
        <Card handleTagClick={handleTagClick} />
        <Posts posts={posts} />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "Do MMMM , YYYY")
          path
          tags
          title
          draft
          photo
        }
        id
      }
    }
  }
`;
